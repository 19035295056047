const env = process.env;
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { buildDataLayer } from "@/constants/analytics";

let interval;

async function loop(to, from) {
  const app = await store.getters["general/GET_ORMLOADED"];
  if (app) {
    clearInterval(interval);
    // console.log("trying to build layere");

    buildDataLayer(
      "pageLoad",
      {
        pageType: to.name,
        pagePath: to.path,
        pageFromType: from.name,
        pageFromPath: from.path,
      },
      null
    );
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => {
      return import(
        /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
      );
    },
  },
  {
    path: "/" + env.VUE_APP_STATIONS_PATH,
    name: "stations",
    component: () => {
      if (process.env.VUE_APP_STATIONPAGE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/Stations.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_STATIONS_PATH + ":name",
    name: "station",
    component: () => {
      if (process.env.VUE_APP_STATIONPAGE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/Station.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_VEHICLES_PATH,
    name: "fahrzeuge",
    component: () => {
      if (process.env.VUE_APP_VEHICLEPAGE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/Vehicles.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_VEHICLES_PATH + ":name",
    name: "fahrzeug",
    component: () => {
      if (process.env.VUE_APP_VEHICLEPAGE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/Vehicles.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_VEHICLES_PATH + ":kfzcat/:name",
    name: "fahrzeug-detail",
    component: () => {
      if (process.env.VUE_APP_VEHICLEPAGE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/VehiclesDetail.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
  {
    path: "/" + env.VUE_APP_RESERVATION_PATH,
    name: "Reservation",
    component: () => {
      return import(
        "../views/Reservation.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP1,
    name: "Reservation_Step1",
    component: () => {
      return import(
        "../views/Reservation/Step1.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" +
      env.VUE_APP_RESERVATION_PATH +
      env.VUE_APP_RESERVATION_PATH_STEP1 +
      "/:name",
    name: "Reservation_Step1_Selected",
    component: () => {
      return import(
        "../views/Reservation/Step1.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP2,
    name: "Reservation_Step2",
    component: () => {
      return import(
        "../views/Reservation/Step2.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP3,
    name: "Reservation_Step3",
    component: () => {
      return import(
        "../views/Reservation/Step3.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_STEP4,
    name: "Reservation_Step4",
    component: () => {
      return import(
        "../views/Reservation/Step4.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path:
      "/" + env.VUE_APP_RESERVATION_PATH + env.VUE_APP_RESERVATION_PATH_FINAL,
    name: "Reservation_Stepsuccess",
    component: () => {
      return import(
        "../views/Reservation/Final.vue" /* webpackChunkName: 'orm-reservation' */
      );
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH,
    name: "Abo",
    component: () => {
      return import(
        "../views/Reservation.vue" /* webpackChunkName: 'orm-abo' */
      );
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH + env.VUE_APP_RESERVATION_PATH_STEP1,
    name: "Abo_Step1",
    component: () => {
      return import("../views/Abo/Step1.vue" /* webpackChunkName: 'orm-abo' */);
    },
  },
  {
    path:
      "/" +
      env.VUE_APP_ABO_PATH +
      env.VUE_APP_RESERVATION_PATH_STEP1 +
      "/:name",
    name: "Abo_Step1_Selected",
    component: () => {
      return import("../views/Abo/Step1.vue" /* webpackChunkName: 'orm-abo' */);
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH + env.VUE_APP_RESERVATION_PATH_STEP2,
    name: "Abo_Step2",
    component: () => {
      return import("../views/Abo/Step2.vue" /* webpackChunkName: 'orm-abo' */);
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH + env.VUE_APP_RESERVATION_PATH_STEP3,
    name: "Abo_Step3",
    component: () => {
      return import(
        "../views/Reservation/Step3.vue" /* webpackChunkName: 'orm-abo' */
      );
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH + env.VUE_APP_RESERVATION_PATH_STEP4,
    name: "Abo_Step4",
    component: () => {
      return import("../views/Abo/Step4.vue" /* webpackChunkName: 'orm-abo' */);
    },
  },
  {
    path: "/" + env.VUE_APP_ABO_PATH + env.VUE_APP_RESERVATION_PATH_FINAL,
    name: "Abo_Stepsuccess",
    component: () => {
      return import(
        "../views/Reservation/Final.vue" /* webpackChunkName: 'orm-abo' */
      );
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH,
    name: "Customer",
    component: () => {
      return import(
        "../views/Customer/Bookings.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "login",
    component: () => {
      return import(
        "../views/Customer/Login.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "Login",
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/" + env.VUE_APP_CUSTOMER_PATH + "register",
  //   component: Register,
  //   name: "Register",
  //   meta: { requiresAuth: false },
  // },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "activate/" + ":token",
    name: "Activate",
    component: () => {
      return import(
        "../views/Customer/Activate.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "activatepassword/" + ":token",
    component: () => {
      return import(
        "../views/Customer/ActivatePassword.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "ActivatePassword",
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "forgot",
    component: () => {
      return import(
        "../views/Customer/ForgotPassword.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "ForgotPassword",
    meta: { requiresAuth: false },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "forgot/" + ":token",
    component: () => {
      return import(
        "../views/Customer/ForgotPassword.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "ForgotPasswordTOKEN",
    meta: { requiresAuth: false },
  },

  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "mydata",
    component: () => {
      return import(
        "../views/Customer/MyData.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "MyData",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "bookings",
    component: () => {
      return import(
        "../views/Customer/Bookings.vue" /* webpackChunkName: 'orm-customer' */
      );
    },
    name: "Bookings",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/" + env.VUE_APP_CUSTOMER_PATH + "contact",
    component: () => {
      return import(
        "../views/Customer/Contact.vue" /* webpackChunkName: 'orm-pages' */
      );
    },
    name: "Contact",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => {
      if (process.env.VUE_APP_STANDALONE === "false") {
        return import(
          /* webpackChunkName: 'orm-reservation' */ "../views/Home.vue"
        );
      } else {
        return import(
          "../views/PageNotFound.vue" /* webpackChunkName: 'orm-pages' */
        );
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});
// eslint-disable-next-line
router.beforeEach(async (to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requiresAuth) {
    const isLoggin = await store.getters["userSession/IS_LOGGEDIN"];
    if (!isLoggin) {
      // this route requires auth, check if logged in
      // if not, redirect to login page.
      return {
        path: "/",
        // save the location we were at to come back later
        // query: { redirect: to.fullPath },
      };
    } else {
      const expiresAt = JSON.parse(isLoggin.expire);
      const routerAuthCheck = new Date().getTime() < expiresAt;
      if (!routerAuthCheck) {
        store.dispatch("userSession/LOGOUT");
      }
    }
  }
});

router.afterEach(async (to, from) => {
  // console.log("route loaded");

  interval = setInterval(() => {
    loop(to, from);
  }, 100);
});

export default router;
