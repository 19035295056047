import apiCall from "../../api/crentAPI";
import store from "@/store";

const ENV =
  process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development"
    ? "ORM_" + process.env.VUE_APP_CUSTOMER_NAME + "_"
    : "ORM_";

/* eslint-disable */

const state = () => ({
  countries: [],
  origins: [],
  salutations:
    JSON.parse(sessionStorage.getItem(ENV + "selectedSalutations")) || [],
  customer: {
    customer: {
      salutation_id: "",
      email: "",
      firstname: "",
      lastname: "",
      company_name: "",
      street: "",
      zipcode: "",
      city: "",
      country_id: "",
      phone_number: "",
      allow_marketing: false,
      create_login: false,
    },
    payment_method_id: "",
    remarks: "",
  },
});
/* eslint-enable */
const getters = {
  GET_COUNTRIES: (state) => state.countries || null,
  GET_ORIGINS: (state) => state.origins || null,
  GET_ORIGIN: (state) => (originName) => {
    return state.origins.find((origin) => origin.origin === originName);
  },
  GET_COUNTRY: (state) => (lang) => {
    return state.countries.find((countrie) => countrie.sign === lang);
  },
  GET_COUNTRY_BY_ID: (state) => (id) => {
    return state.countries.find((countrie) => countrie.id === id);
  },
  GET_SALUTATIONS: (state) => state.salutations || null,
  GET_SALUTATION: (state) => (id) => {
    if (state.salutations.length > 0 && id !== null && id !== "") {
      return state.salutations.find(
        (salutation) => salutation.id === parseInt(id)
      ).salutation;
    } else {
      return null;
    }
  },
  GET_CUSTOMER: (state) =>
    JSON.parse(sessionStorage.getItem(ENV + "selectedCustomer")) ||
    JSON.parse(JSON.stringify(state.customer)),
};
// actions
const actions = {
  async SET_ORIGINS({ commit }) {
    let status = false;
    await apiCall.get("/api/rent/customers/origins", "", (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["ORMgeneral/SET_ORIGINS", status];
      } else {
        status = true;
      }
      commit("MUTATE_ORIGINS", result);
    });
    return ["customer/SET_ORIGINS", status];
  },
  async SET_COUNTRIES({ commit }) {
    let status = false;
    await apiCall.get("/api/rent/customers/countries", "", (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["ORMgeneral/SET_COUNTRIES", status];
      } else {
        status = true;
      }
      commit("MUTATE_COUNTRIES", result);
    });
    return ["customer/SET_COUNTRIES", status];
  },
  async SET_SALUTATIONS({ commit }) {
    let status = false;
    await apiCall.get("/api/rent/customers/salutations", "", (result) => {
      if (result.error || result.errorCode) {
        status = false;
        return ["ORMgeneral/SET_SALUTATIONS", status];
      } else {
        status = true;
      }
      commit("MUTATE_SALUTATIONS", result);
    });
    return ["customer/SET_SALUTATIONS", status];
  },
  // eslint-disable-next-line
  async AUTHENTICATE({ commit }, credentials) {
    let status = false;
    // console.log(credentials);
    await apiCall.postHeaders(
      "/api/rent/customers/authenticate",
      credentials,
      (result) => {
        console.log(result.status);
        if (
          result.status == 201 ||
          result.status == 200 ||
          result.status == 204
        ) {
          status = true;
          store.dispatch("userSession/SET_LOGGEDINSESSION", result.data.token);
        } else {
          status = false;
        }
      }
    );
    return status;
  },
  // eslint-disable-next-line
  async FORGOTPASSWORD({ commit }, credentials) {
    let status = false;
    // console.log(credentials);
    await apiCall.putHeaders(
      "/api/rent/customers/authenticate/password/forgot",
      credentials,
      (result) => {
        console.log(result.status);
        if (result.status == 205 || result.status == 204) {
          status = true;
          // store.dispatch("userSession/SET_LOGGEDINSESSION", result.data.token);
        } else {
          status = false;
        }
      }
    );
    return status;
  },
  // eslint-disable-next-line
  async CHANGEPASSWORD({ commit }, credentials) {
    let status = false;
    // console.log(credentials);
    await apiCall.putHeaders(
      "/api/rent/customers/password",
      credentials,
      (result) => {
        console.log(result.status);
        if (result.status == 205 || result.status == 204) {
          status = true;
          // store.dispatch("userSession/SET_LOGGEDINSESSION", result.data.token);
        } else {
          status = false;
        }
      }
    );
    return status;
  },

  // eslint-disable-next-line
  async ACTIVATE({ commit }, credentials) {
    let status = false;
    await apiCall.putHeaders(
      "/api/rent/customers/authenticate/activate",
      credentials,
      (result) => {
        if (
          result.status == 201 ||
          result.status == 200 ||
          result.status == 204 ||
          result.status == 205
        ) {
          status = true;
        } else {
          console.log("active false");
          status = false;
        }
      }
    );
    return status;
  },
  // eslint-disable-next-line
  async GET_USER_DATA({ commit }) {
    let response = false;
    await apiCall.getHeaders("/api/rent/customers/me", "", (result) => {
      if (result.status == 401) {
        store.dispatch("userSession/LOGOUT");
      }
      if (
        result.status == 201 ||
        result.status == 200 ||
        result.status == 204
      ) {
        // console.log(result);
        response = result.data;
        commit("MUTATE_USER_DATA", result.data);
      }
    });

    return response;
  },
  async GET_BOOKINGS() {
    let response = false;
    await apiCall.getHeaders("/api/rent/customers/bookings", "", (result) => {
      if (
        result.status == 201 ||
        result.status == 200 ||
        result.status == 204
      ) {
        const bookings = [];
        for (const key in result.data) {
          const element = result.data[key];
          for (const key in element) {
            bookings.push(element[key]);
          }
        }
        response = result.data;
      }
    });

    return response;
  },
  // eslint-disable-next-line
  async SEND_CONTACT({ commit }, payload) {
    let response = "";

    await apiCall.postHeaders("/api/rent/contacts", payload, (result) => {
      response = { status: result.status, data: result.data };
    });
    return response;
    // return ["customer/SET_SALUTATIONS", status];
  },
  // eslint-disable-next-line
  async CHECK_EMAIL_EXCISTS({ commit }, payload) {
    let response = "";

    await apiCall.postHeaders(
      "/api/rent/customers/check_login",
      { email: payload },
      (result) => {
        response = { status: result.status, data: result.data };
      }
    );
    return response;
  },
  DELETE_LOGIN_CUSTOMER({ commit }) {
    commit("MUTATE_DELETE_LOGIN_CUSTOMER");
  },
};
const mutations = {
  MUTATE_COUNTRIES(state, countries) {
    state.countries = countries;
  },
  MUTATE_ORIGINS(state, origins) {
    state.origins = origins;
  },
  MUTATE_SALUTATIONS(state, salutations) {
    state.salutations = salutations;
    sessionStorage.setItem(
      ENV + "selectedSalutations",
      JSON.stringify(salutations)
    );
  },
  MUTATE_USER_DATA(state, userdata) {
    let salutation = "1";
    if (userdata.salutation) {
      salutation =
        Object.keys(userdata.salutation).length >= 0
          ? userdata.salutation.id
          : "1";
    }
    state.customer.customer = {
      salutation_id: salutation,
      email: userdata.email,
      firstname: userdata.firstname,
      lastname: userdata.lastname,
      name1: userdata.name1,
      company_name: userdata.company_name,
      street: userdata.street,
      zipcode: userdata.zipcode,
      city: userdata.city,
      country_id: userdata.country_id,
      phone_number: userdata.phone_number,
      allow_marketing: userdata.allow_marketing,
      create_login: false,
      birth_date: userdata.birth_date,
      mobile_phone: userdata.mobile_phone,
    };
    sessionStorage.setItem(
      ENV + "selectedCustomer",
      JSON.stringify(state.customer)
    );
  },
  MUTATE_DELETE_LOGIN_CUSTOMER(state) {
    state.customer = {
      customer: {
        salutation_id: "",
        email: "",
        firstname: "",
        lastname: "",
        company_name: "",
        street: "",
        zipcode: "",
        city: "",
        country_id: "",
        phone_number: "",
        allow_marketing: false,
        create_login: false,
      },
      payment_method_id: "",
      remarks: "",
    };

    sessionStorage.removeItem(ENV + "selectedCustomer");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

//   customer: {
//     salutation_id: "",
//     email: "",
//     firstname: "",
//     lastname: "",
//     title: "",
//     company_name: "",
//     street: "",
//     zipcode: "",
//     city: "",
//     birth_date: "" /* 2021-02-05T09:38:59.310Z */,
//     country_name: "",
//     country_id: "54",
//     phone_number: "",
//     drivers_license_number: "",
//     drivers_license_date: "" /* 2021-02-05T09:38:59.310Z */,
//     drivers_license_city: "",
//     pass_number: "",
//     pass_date: "" /* 2021-02-05T09:38:59.310Z */,
//     pass_city: "",
//     allow_marketing: true,
//     create_login: false,
//   },

//   paymentMethod_id: "",
//   remarks: "",
//   delivery_street: "",
//   delivery_zipcode: "",
//   delivery_city: "",
//   pickup_street: "",
//   pickup_zipcode: "",
//   pickup_city: "",
// },
